<template>
  <div style="height: 100%;margin: auto">
    <div style="width: 600rem;margin: auto;">
      <el-form label-width="150rem">
        <el-form-item label="开始展示时间">
          <el-date-picker v-model="dataForm.start_at" format="yyyy-MM-dd HH:mm" placeholder="选择开始展示时间" type="datetime" value-format="yyyy-MM-dd HH:mm"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束展示时间">
          <el-date-picker v-model="dataForm.end_at" format="yyyy-MM-dd HH:mm" placeholder="选择结束展示时间" type="datetime" value-format="yyyy-MM-dd HH:mm"></el-date-picker>
        </el-form-item>
        <el-form-item v-if="dataForm.role === 5 || dataForm.role === 6" label="学生最低版本">
          <el-select v-model="dataForm.version_id" placeholder="请选择学生最低版本">
            <el-option v-for="item in versionArr" :key="item.id" :label="item.name+item.code" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运营位置管理">
          <el-select v-model="dataForm.position_id" placeholder="请选择运营位置管理">
            <el-option v-for="item in positionArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择底图">
          <el-button @click="onChangeRange">点击选择</el-button>
          <div v-if="JSON.stringify(image) !== '{}'">
            <el-tag closable @close="onCloseImg">
              {{ image.name }}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="图片展示文案">
          <el-input v-model="dataForm.image_text" maxlength="10" placeholder="请输入图片展示文案" show-word-limit style="width: 220rem"></el-input>
        </el-form-item>
      </el-form>
    </div>


    <el-dialog :before-close="onBeforeClose" :visible.sync="dialogVisible" title="选择底图" width="80%">
      <div style="display: flex;flex-wrap: wrap">
        <div v-for="(i,n) in imageArr" style="width: 50%;display: flex;margin: 24rem 0;align-items: center;position: relative" @click="onClickImage(i,n)">
          <div style="margin-right: 4rem;font-size: 12rem">{{ i.name }}</div>
          <img :src="i.image_url" :style="{opacity: index !== -1 ? n !== index ? 0.6 : '' : '' }" alt="" style="width: 80%;">
          <img v-if="n === index " :src="$OssAddress+'select.png'" alt="" style="position: absolute;width: 40rem;bottom: -2rem;right: 20rem">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="onDialogVisibleSure">保 存</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》 ';
export default {
  props: {
    dataForm: Object,
    versionArr: Array,
    positionArr: Array,
    imageArr: Array,
  },
  data() {
    return {
      dialogVisible: false, // 选择范围弹窗
      checkList: [], // 选择范围数据
      loading: false, // 学生搜索加载过渡
      index: -1,
      indexTo: -1,
      image: {}
    }
  }
  ,
// 计算属性 类似于 data 概念
  computed: {}
  ,
// 监控 data 中的数据变化
  watch: {}
  ,
// 方法集合
  methods: {
    // 点击选择按钮
    onChangeRange() {
      this.dialogVisible = true
      this.indexTo = this.index
    },
    // 选择范围弹窗确定按钮
    onDialogVisibleSure() {
      this.image = this.imageArr[this.imageArr.findIndex(item => item.id === this.dataForm.image_id)]
      this.dialogVisible = false
    },
    // 学生搜索方法
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        this.loading = false;
        this.studentArr = []
      } else {
        this.studentArr = [];
      }
    },
    // 点击底图
    onClickImage(val, index) {
      this.index = index
      this.dataForm.image_id = val.id
    },
    // 删除选择底图
    onCloseImg() {
      this.image = {}
      this.dataForm.image_id = ''
      this.index = -1
    },
    onBeforeClose() {
      this.index = this.indexTo
      this.dialogVisible = false
    }
  },
}
</script>
<style lang="scss" scoped>
.itemMargin {
  display: flex;
  margin-bottom: 24rem;
}

.leftFont {
  font-size: 14rem;
  color: #333333;
  margin-right: 24rem;
  text-align: end;
  display: inline-block;
  width: 100rem !important;
}

.el-scrollbar:nth-of-type(1) {
  display: none !important;
}

.el-scrollbar__view .el-time-spinner__list {
  display: none !important;
}

.el-time-spinner {
  text-align: center;
}
</style>
