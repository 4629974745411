<template>
  <div style="width: 100%">
    <div class="circle-box">
      <div v-for="item in num" class="circle-line-box">
        <div :style="{background: item <= index ? '' : '#ccc'}" class="circle">
          <i v-if="item <= index " class="el-icon-check"></i><span v-else>{{ item }}</span>
        </div>
        <div v-if="item < num" :style="{background: item < index  ? '' : '#ccc'}" class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Steps",
  props: {
    index: [String, Number],
    num: Number
  }
}
</script>

<style lang="scss" scoped>
.line {
  height: 2rem;
  background: #1d2088;
  flex: 1;
}

.circle {
  width: 35rem !important;
  height: 35rem;
  background: #1d2088;
  border-radius: 50%;
  font-size: 24rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.circle-line-box {
  display: flex;
  align-items: center;
  width: 80%;
  flex: auto;
}

.circle-line-box:last-child {
  width: 40rem;
}
</style>
