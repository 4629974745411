<template>
	<edit-template style="padding: 24rem">

    <div class="header">
      <steps :index="pageIndex" :num="3"></steps>
    </div>

    <div class="body">
      <one v-show="pageIndex===1" ref="dataOne" :content-type-arr="paramObj.content_type" :dataForm="dataForm" :grade-arr="paramObj.grade" :role-arr="paramObj.role" :school-arr="paramObj.school" :subject-arr="paramObj.subject">
      </one>

      <tow v-if="pageIndex===2" ref="dataTwo" :content-arr="paramObj.paper[dataForm.role] ? paramObj.paper[dataForm.role] : []" :dataForm="dataForm"></tow>

      <three v-show="pageIndex===3" ref="dataThree" :dataForm="dataForm" :image-arr="paramObj.image" :position-arr="paramObj.position" :version-arr="paramObj.version"></three>
    </div>

    <template slot="footer">
      <el-button :disabled="disabled" type="primary" @click="onNextStep">{{ pageIndex === 3 ? '保存' : '下一步' }}</el-button>
      <el-button v-if="pageIndex!==1" @click="onLastStep">上一步</el-button>
    </template>

</edit-template>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》 ';
import one from './components/one'
import tow from './components/tow'
import three from './components/three'
import Steps from './components/Steps'

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  // import 引入的组件需要注入到对象中才能使用
  components: {
    one,
    tow,
    three,
    Steps,
  },
  watch: {
    dataForm: {
      handler(val, newVal) {
        // 监听内容类型的变化实现下一步是否保存当值和情况值的操作
        this.content_type = newVal.content_type
        // 监听第一页的数据变化实现下一步按钮是否可点击
        if (this.pageIndex === 1) {
          if (val.role === 1 || val.role === 2){
            this.disabled = !(val.name !== "" && val.school.length > 0 && val.role !== '' && val.content_type !== '');
            return
          }
          if (val.role === 3 || val.role === 4 || val.role === 5){
            this.disabled = !(val.name !== "" && val.school.length > 0 && val.role !== '' && val.range.length > 0 && val.content_type !== '');
            return
          }
          if (val.role === 6){
            this.disabled = !(val.name !== "" && val.school.length > 0 && val.role !== '' && val.range !== '' && val.content_type !== '');
            return
          }
        }
        if (this.pageIndex === 2) {
          this.disabled = true
          if (this.dataForm.content_type === 1 || this.dataForm.content_type === 2) {
            if (this.dataForm.content !== '') {
              return this.disabled = false
            }
          } else {
            if (this.dataForm.content !== "<p><br></p>" && this.dataForm.title !== '' && this.dataForm.publisher !== '' && this.dataForm.is_reply !== '') {
              return this.disabled = false
            }
          }
        }
        if (this.pageIndex === 3) {
          this.disabled = true
          if (this.dataForm.role === 5 || this.dataForm.role === 6) {
            if (this.dataForm.start_at !== '' && this.dataForm.end_at !== '' && this.dataForm.version_id !== '' && this.dataForm.position_id !== '' && this.dataForm.image_id !== '' && this.dataForm.image_text !== '') {
              return this.disabled = false
            }
          } else {
            if (this.dataForm.start_at !== '' && this.dataForm.end_at !== '' && this.dataForm.position_id !== '' && this.dataForm.image_id !== '' && this.dataForm.image_text !== '') {
              return this.disabled = false
            }
          }
        }
      },
      deep: true
    }
  },
  data() {
    // 这里存放数据
    return {
      dataForm: {
        name: '',//运营位名称
        school: [],//校区，格式[1, 2]
        role: '',//用户类型
        range: [], //范围，格式[1, 2]
        content_type: '',//内容类型 1 问卷 2 外部链接 3 文本内容
        content: '',//内容
        title: '',//标题（内容类型为3时传参）
        publisher: '',//发布人（内容类型为3时传参）
        is_reply: '',//是否可回复 0 否 1 是（内容类型为3时传参）
        start_at: '',//开始时间，格式：2022-01-01 10:00
        end_at: '',//结束时间，格式：2022-01-01 10:00
        version_id: '',//学生端最低版本（角色为5时传参， 默认为0）
        position_id: '',//运营位位置
        image_id: '',//底图id
        image_text: '',//图片展示文案
      },
      pageIndex: 1,
      paramObj: {
        paper: {}
      },
      arr: [],
      disabled: true,
      content_type: null,
    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 方法集合
  methods: {
    onNextStep() {
      if (this.pageIndex === 1) {
        this.pageIndex = 2
        if (this.dataForm.content_type === 1 || this.dataForm.content_type === 2) {
          this.disabled = this.dataForm.content === '';
          return
        }
        if (this.dataForm.content_type === 3) {
          if (this.dataForm.content === "<p><br></p>" || this.dataForm.content === "") {
            this.dataForm.content = "<p><br></p>"
          }
          this.disabled = this.dataForm.content === '<p><br></p>' || this.dataForm.title === '' || this.dataForm.publisher === '' || this.dataForm.is_reply === ''
          return
        }
      }
      if (this.pageIndex === 2) {
        if (this.dataForm.role === 5 || this.dataForm.role === 6) {
          this.disabled = this.dataForm.start_at === '' || this.dataForm.end_at === '' || this.dataForm.version_id === '' || this.dataForm.position_id === '' || this.dataForm.image_id === '' || this.dataForm.image_text === '';
        } else {
          this.disabled = this.dataForm.start_at === '' || this.dataForm.end_at === '' || this.dataForm.position_id === '' || this.dataForm.image_id === '' || this.dataForm.image_text === '';
        }
        this.pageIndex = 3
        return
      }
      if (this.pageIndex === 3) {
        let data = JSON.parse(JSON.stringify(this.dataForm))
        if (data.role === 1 || data.role === 2) {
          delete data.range
        }
        if (data.content_type !== 3) {
          delete data.title
          delete data.publisher
          delete data.is_reply
        }
        if (typeof data.range === 'string' || typeof data.range === 'number') {
          data.range = [data.range]
        }
        this.$_axios2.post('api/operation/index', data).then(res => {
          if (res.data.status === 0) {
            this.$store.commit("setPage",1);
            this.$message.success('新增成功')
            this.$router.back()
          }
        })
      }
      setTimeout(() => {
        this.$forceUpdate()
      }, 10)
    },
    // 上一步
    onLastStep() {
      this.disabled = false
      if (this.pageIndex === 2) {
        sessionStorage.setItem('content_type', this.dataForm.content_type)
      }
      this.pageIndex = this.pageIndex - 1
    }
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
    this.$_axios2.get('api/operation/index/get-param').then(res => {
      this.paramObj = res.data.data
    })
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
  },
}
</script>
<style lang="scss" scoped>
.header {
  width: 35%;
  height: 40rem;
  margin: 68rem auto 48rem;
}

.body {
  width: 100%;
  padding: 24rem 0;
}

.footer {
  width: 100%;
  height: 40rem;
  text-align: center;
  margin-top: 48rem;
}
</style>
