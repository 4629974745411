<template>
  <div style="height: 100%">
    <div style="width: 950rem;margin: auto">
      <el-form label-width="150rem">
        <el-form-item label="运营位名称">
          <el-input v-model="dataForm.name" maxlength="15" placeholder="请输入运营位管理" style="width: 180rem"></el-input>
        </el-form-item>
        <el-form-item label="选择校区">
          <el-checkbox-group v-model="dataForm.school" @change="onChangeSchool">
            <el-checkbox v-for="item in schoolArr" :label="item.id">{{ item.school_name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="用户类型">
          <el-radio v-for="item in roleArr" v-model="dataForm.role" :label="item.id" style="margin-top: 4rem" @change="onChangeRole">{{ item.name }}</el-radio>
        </el-form-item>
        <el-form-item v-if="dataForm.role === 3 || dataForm.role === 4 || dataForm.role === 5" label="选择范围">
          <el-button style="height: 40rem" @click="onChangeRange">点击选择</el-button>
          <div>
            <el-tag v-for="(item,index) in rangeArr" :key="item.id" :disable-transitions="true" closable style="margin-right: 12rem" @close="closable(index)">
              {{ dataForm.role === 3 ? item.subject_name : item.enrollment + item.name }}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item v-if="dataForm.role === 6" label="学生姓名">
          <el-select v-model="dataForm.range" :loading="loading" :remote-method="remoteMethod" filterable placeholder="请输入学生姓名" remote reserve-keyword>
            <el-option v-for="item in studentArr" :key="item.id" :label="item.student_name+'_'+item.student_no" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容类型">
          <el-radio v-for="item in contentTypeArr" v-model="dataForm.content_type" :label="item.id" style="margin-top: 3rem" @change="onChangeContentType">{{ item.name }}</el-radio>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog :before-close="()=>dialogVisible=false" :visible.sync="dialogVisible" title="选择可查看用户范围" width="30%">
      <div>
        <span>{{ dataForm.role === 3 ? '选择学科组' : '选择年级' }}</span>
        <div style="margin-top: 12rem">
          <el-checkbox-group v-model="checkList">
            <el-checkbox v-for="item in dataForm.role === 3 ? subjectArr : gradeArr" :label="item.id" :style="{width:dataForm.role === 3 ? '100rem' : '150rem'}" style="margin: 4rem">
              {{ dataForm.role === 3 ? item.subject_name : item.enrollment + item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="onDialogVisibleSure">保 存</el-button>
    <el-button @click="dialogVisible = false">取 消</el-button>
  </span>
    </el-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》 ';
export default {
  props: {
    dataForm: Object,
    schoolArr: Array,
    roleArr: Array,
    contentTypeArr: [Array, String, Number],
    subjectArr: Array,
    gradeArr: Array,
  },
  data() {
    return {
      dialogVisible: false, // 选择范围弹窗
      checkList: [], // 选择范围数据
      studentArr: [], // 学生总数据
      loading: false, // 学生搜索加载过渡
      rangeArr: [], // 选择范围显示
    }
  }
  ,
// 计算属性 类似于 data 概念
  computed: {}
  ,
// 监控 data 中的数据变化
  watch: {
    rangeArr: {
      handler: (val, oldVal) => {
        console.log(val)
      },
      deep: true
    }
  }
  ,
// 方法集合
  methods: {
    // 点击选择按钮
    onChangeRange() {
      this.dialogVisible = true
      this.checkList = []
      if (this.rangeArr.length) {
        this.rangeArr.forEach(item => {
          this.checkList.push(item.id)
        })
      }
    },
    // 选择范围弹窗确定按钮
    onDialogVisibleSure() {
      this.rangeArr = []
      this.dataForm.range = []
      if (this.dataForm.role === 3) {
        for (let task of this.checkList) {
          const itemJson = this.subjectArr.find((item) => {
            return item.id === task
          });
          this.rangeArr.push(itemJson)
          this.dataForm.range.push(itemJson.id)
        }
      } else {
        for (let task of this.checkList) {
          const itemJson = this.gradeArr.find((item) => {
            return item.id === task
          });
          this.rangeArr.push(itemJson)
          this.dataForm.range.push(itemJson.id)
        }
      }

      this.dialogVisible = false
    },
    // 学生搜索方法
    remoteMethod(query) {
      let data = JSON.parse(JSON.stringify(this.dataForm.school))
      if (query !== '') {
        this.loading = true;
        this.$_axios2.get('api/operation/index/student', { params: { keyword: query, school: data.toString() } }).then(res => {
          if (res.data.data.length) this.studentArr = res.data.data
          else this.studentArr = []
          this.loading = false;

        })
      } else {
        this.studentArr = [];
      }
    },

    // 校区变更方法
    onChangeSchool(){
      this.dataForm.range = ''
      this.studentArr = []
      this.rangeArr = []
    },

    // 切换类型
    onChangeRole() {
      this.dataForm.range = this.dataForm.role === 3 ? [] : ''
      this.dataForm.content = ''
      this.studentArr = []
      this.checkList = []
      this.rangeArr = []
    },

    // 删除页签
    closable(index) {
      this.rangeArr.splice(index, 1)
      this.dataForm.range.splice(index, 1)
    },

    // 内容类型变更
    onChangeContentType() {
      this.dataForm.content = ''
    }
  },
// 生命周期 - 挂载完成（可以访问 DOM 元素）
}
</script>
<style lang="scss" scoped>

</style>
