<template>
  <div style="height: 100%">
    <div v-if="dataForm.content_type === 1" style="width: 600rem;margin: auto;">
      <el-form label-width="150rem">
        <el-form-item label="请选择投放问卷">
          <el-select v-model="dataForm.content" placeholder="请选择问卷">
            <el-option v-for="item in contentArr" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <div v-if="dataForm.content_type === 2" style="width: 600rem;margin: auto;">
      <div style="margin-bottom: 24rem">输入链接地址前，需通知信息部，否则投放内容无法正常展示</div>
      <el-form label-width="150rem">
        <el-form-item label="链接地址">
          <el-input v-model="dataForm.content" :rows="8" placeholder="请输入外部链接地址" resize=“none” style="width: 100%" type="textarea"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div v-if="dataForm.content_type === 3">
      <el-form label-width="150rem">
        <el-form-item label="文本标题">
          <el-input v-model="dataForm.title" maxlength="30" placeholder="请输入文本标题"></el-input>
        </el-form-item>
        <el-form-item label="发布人">
          <el-input v-model="dataForm.publisher" maxlength="30" placeholder="请输入发布人"></el-input>
        </el-form-item>
        <el-form-item label="是否可回复">
          <el-radio v-model="dataForm.is_reply" label="1">是</el-radio>
          <el-radio v-model="dataForm.is_reply" label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="内容正文">
          <div style="border: 1rem solid #ccc;width: 80%">
            <Toolbar
                :defaultConfig="toolbarConfig"
                :editor="editor"
                :mode="mode"
                style="border-bottom: 1rem solid #ccc"
            />
            <Editor
                v-model="dataForm.content"
                :defaultConfig="editorConfig"
                :mode="mode"
                style="height: 500rem; overflow-y: hidden;"
                @onCreated="onCreated"
            />
          </div>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》 ';

import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css'
import {Message} from 'element-ui'

export default {
  components: {Editor, Toolbar},
  props: {
    dataForm: Object,
    contentArr: Array
  },
  data() {
    return {
      editor: null,
      toolbarConfig: {
        excludeKeys: [
          'fullScreen',
          'insertVideo'
        ],
      },
      mode: 'simple', // or 'default',
      editorConfig: {
        placeholder: '请输入内容...',
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 图片上传
          uploadImage: {
            server: process.env.VUE_APP_URL_TWO + '/api/site/upload',
            fieldName: 'upload',
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 5 * 1024 * 1024, // 5M
            // 最多可上传几个文件，默认为 100
            // maxNumberOfFiles: 1,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {},
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,

            // 自定义增加 http  header
            headers: {
              authorization: 'Bearer ' + sessionStorage.getItem('TOKEN')
              // Accept: 'text/x-json',
              // otherKey: 'xxx'
            },

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: false,

            // 超时时间，默认为 10 秒
            timeout: 10 * 1000, //10 秒

            // 上传前
            // onBeforeUpload(files) {
            //   Message({
            //     message: '图片正在上传中,请耐心等待',
            //     duration: 0,
            //     customClass: 'uploadTip',
            //     iconClass: 'el-icon-loading',
            //     showClose: true
            //   });
            //   return files;
            // },

            // 自定义插入图片
            customInsert(res, insertFn) {
              // 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
              // 先关闭等待的Message
              // Message.closeAll();
              if (res.status === 0) {
                Message.success({
                  message: '上传成功'
                });
                insertFn(res.data.file_addr, '', res.data.file_addr);
              } else {
                Message.error({
                  message: res.msg
                });
              }
            },

            // 单个文件上传成功之后
            onSuccess(file, res) {
              // console.log(`${ file.name } 上传成功`, res);
            },

            // 单个文件上传失败
            onFailed(file, res) {
              Message.error('上传失败请重试')
            },

            // 上传进度的回调函数
            onProgress(progress) {
              // progress 是 0-100 的数字
            },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              if (file.size / 1024 / 1024 > 5) Message.error('上传失败,上传文件过大')
              else Message.error('上传失败请重试')
            }
          },
        }
      },

      arr: [],
    }
  },
  // 方法集合
  methods: {
    // 点击选择按钮
    onChangeRange() {
      this.dialogVisible = true
    },
    // 选择范围弹窗确定按钮
    onDialogVisibleSure() {
      this.dataForm.name3 = this.checkList
      this.dialogVisible = false
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
}
</script>
<style lang="scss" scoped>
.itemMargin {
  display: flex;
  height: 64rem;
}

.leftFont {
  font-size: 14rem;
  color: #333333;
  margin-right: 24rem;
  text-align: end;
  display: inline-block;
  width: 100rem !important;
}

::v-deep .el-textarea__inner {
  resize: none;
}

::v-deep .w-e-text-container {
  font-family: "Roboto", sans-serif;
}
</style>
